<template>
  <b-card-code no-body title="Commodity List">

    <b-modal id="modal-commodity-price" size="lg" title="Price History">

      <commodity-price-history-chart v-if="price_history_slug != null" :commodity_slug="price_history_slug"></commodity-price-history-chart>

      <template #modal-footer="{}">
      </template>
    </b-modal>

    <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

          <div class="col-md-6">
            <b-button @click="cleanupForm()" v-if="checkPermission('create commodity')" v-b-modal.form-commodity-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add Commodity">
              <plus-circle-icon size="1x" class="custom-class"></plus-circle-icon> Add Commodity
            </b-button>

            <b-modal id="form-commodity-modal" size="lg" :title="editSlug != null ? 'Edit Commodity' : 'Add Commodity'">
              <div class="form">

                <h5>Commodity Information</h5>

                <div class="form-group">
                  <label for="">Name:</label>
                  <input type="text" class="form-control" v-bind:class="validations.hasOwnProperty('name') && validations.name.length > 0 ? 'is-invalid' : ''" v-model="formPayload.name" placeholder="Commodity name">
                  <small class="text-danger" v-if="validations.hasOwnProperty('name') && validations.name.length > 0">
                    {{ validations.name.join(', ') }}
                  </small>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Cover:</label><br>
                      <input type="file" id="file" ref="file" @change="changeCover()">
                      <br>
                      <small class="text-danger" v-if="validations.hasOwnProperty('cover') && validations.cover.length > 0">
                        {{ validations.cover.join(', ') }}
                      </small>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="formPayload.cover_url != null">
                      <small>Current cover:</small><br>
                      <img v-bind:src="formPayload.cover_url" style="max-width: 100%;">
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Category:</label>
                  <select class="form-control" v-model="formPayload.category_id">
                    <option selected value="">-- Select Category --</option>
                    <option v-for="category in categories" v-bind:key="category.slug" v-bind:value="category.id">{{ category.name }}</option>
                  </select>
                  <small class="text-danger" v-if="validations.hasOwnProperty('category_id') && validations.category_id.length > 0">
                    {{ validations.category_id.join(', ') }}
                  </small>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Commodity Code:</label>
                      <input type="text" class="form-control" v-bind:class="validations.hasOwnProperty('code') && validations.code.length > 0 ? 'is-invalid' : ''" v-model="formPayload.code" placeholder="Commodity code in App">
                      <small class="text-danger" v-if="validations.hasOwnProperty('code') && validations.code.length > 0">
                        {{ validations.code.join(', ') }}
                      </small>
                    </div>formPayload
                  </div>
                </div>

                <div class="row">

                  <div class="col-12">
                    <div class="form-group">
                    <label for="is_sync_to_epact">Link to EPACT:</label>
                    <div class="custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="is_sync_to_epact" name="is_sync_to_epact" v-model="formPayload.is_sync_to_epact">
                      <label class="custom-control-label" :for="'is_sync_to_epact'">
                        Yes
                      </label>
                    </div>
                  </div>
                  </div>

                  <div class="col-6" v-if="formPayload.is_sync_to_epact">
                    <div class="form-group">
                      <label for="">EPACT Commodity Code:</label>
                      <input type="text" class="form-control" v-bind:class="validations.hasOwnProperty('epact_commodity_name') && validations.epact_commodity_name.length > 0 ? 'is-invalid' : ''" v-model="formPayload.epact_commodity_name" placeholder="Commodity code in EPACT">
                      <small class="text-info">This field used to sync with EPACT API</small>
                      <small class="text-danger" v-if="validations.hasOwnProperty('epact_commodity_name') && validations.epact_commodity_name.length > 0">
                        <br>{{ validations.epact_commodity_name.join(', ') }}
                      </small>
                    </div>
                  </div>
                  <div class="col-6" v-if="formPayload.is_sync_to_epact">
                    <div class="form-group">
                      <label for="">EPACT Commodity Amount Convertion:</label>
                      <input type="text" class="form-control" v-bind:class="validations.hasOwnProperty('epact_convertion_amount') && validations.epact_convertion_amount.length > 0 ? 'is-invalid' : ''" v-model="formPayload.epact_convertion_amount" placeholder="Commodity convertion">
                      <small class="text-info">This field used to convert from/to EPACT API</small>
                      <small class="text-danger" v-if="validations.hasOwnProperty('epact_convertion_amount') && validations.epact_convertion_amount.length > 0">
                        <br>{{ validations.epact_convertion_amount.join(', ') }}
                      </small>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                  <label for="">Commodity Quality:</label>
                  <input type="text" class="form-control" v-bind:class="validations.hasOwnProperty('quality') && validations.quality.length > 0 ? 'is-invalid' : ''" v-model="formPayload.quality" placeholder="Commodity quality">
                  <small class="text-danger" v-if="validations.hasOwnProperty('quality') && validations.quality.length > 0">
                    {{ validations.quality.join(', ') }}
                  </small>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Commodity Unit:</label>
                      <select class="form-control" v-model="formPayload.unit">
                        <option v-for="unit in units" v-bind:key="unit" v-bind:value="unit">{{ unit }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Amount per Lot:</label>
                      <div class="input-group">
                        <input type="number" class="form-control" v-bind:class="validations.hasOwnProperty('amount_per_lot') && validations.amount_per_lot.length > 0 ? 'is-invalid' : ''" v-model="formPayload.amount_per_lot" placeholder="Amount per Lot">
                        <div class="input-group-append">
                          <span class="input-group-text">{{ formPayload.unit }}</span>
                        </div>
                      </div>
                      <small class="text-danger" v-if="validations.hasOwnProperty('amount_per_lot') && validations.amount_per_lot.length > 0">
                        {{ validations.amount_per_lot.join(', ') }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Description:</label>
                  <quill-editor
                    v-model="formPayload.description"
                    :options="editorOption"
                  >
                    <div
                      id="toolbar"
                      slot="toolbar"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold">
                        Bold
                      </button>
                      <button class="ql-italic">
                        Italic
                      </button>

                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>

                      <!-- Add subscript and superscript buttons -->
                      <button
                        class="ql-script"
                        value="sub"
                      />
                      <button
                        class="ql-script"
                        value="super"
                      />

                    </div>
                  </quill-editor>
                  <small class="text-danger" v-if="validations.hasOwnProperty('description') && validations.description.length > 0">
                    {{ validations.description.join(', ') }}
                  </small>
                </div>

                <br>
                  <h5>Access User to this Commodity</h5>


                  <button class="btn btn-sm btn-success" @click="addGrantedCommodity()">
                    <feather-icon
                        icon="PlusIcon"
                    /> Add Item
                  </button><br>
                  <small>This configuration will applied only if auction is restricted, if auction is not restricted then all user will have access to this commodity.</small>
                  <br>
                  <table class="table table-hover">
                    <tr v-for="granted, index in formPayload.commodity_user_access" :key="index">
                      <template v-if="granted.user_id == null || granted.user_id == '' || users.map(function(item){ return item.id; }).includes(granted.user_id)">
                        <td class="pl-0" width="45%">
                          <select class="form-control" v-model="formPayload.commodity_user_access[index].user_id">
                            <option value="">-- Select User --</option>
                            <template v-for="user in users">
                              <option :key="user.id" :value="user.id" v-if="!formPayload.commodity_user_access.map(function(item){ return item.user_id; }).includes(user.id) || user.id == formPayload.commodity_user_access[index].user_id">
                                {{ user.code == null ? '' : (user.code + ' - ')}} {{ user.name }}
                              </option>
                            </template>
                          </select>
                        </td>
                        <td width="15%" style="padding-top: 20px;" class="pl-0">
                          <div class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.commodity_user_access[index].can_see" :id="'checkbox_commodity_see_' + index">
                              <label class="custom-control-label" :for="'checkbox_commodity_see_' + index">
                                Can See
                              </label>
                            </div>
                          </div>
                        </td>
                        <td width="15%" style="padding-top: 20px;" class="pl-0">
                          <div class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.commodity_user_access[index].can_buy" :id="'checkbox_commodity_buy_' + index">
                              <label class="custom-control-label" :for="'checkbox_commodity_buy_' + index">
                                Can Buy
                              </label>
                            </div>
                          </div>
                        </td>
                        <td width="15%" style="padding-top: 20px;" class="pl-0">
                          <div class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.commodity_user_access[index].can_sell" :id="'checkbox_commodity_sell_' + index">
                              <label class="custom-control-label" :for="'checkbox_commodity_sell_' + index">
                                Can Sell
                              </label>
                            </div>
                          </div>
                        </td>
                        <td class="text-right pr-0">
                          <button class="btn btn-sm btn-danger ml-1" @click="removeGrantedCommodity(index)">
                            <feather-icon
                                icon="XIcon"
                            />
                          </button>
                        </td>
                      </template>
                    </tr>
                  </table>
                  <small class="text-danger" v-if="validations.hasOwnProperty('commodity_user_access') && validations.commodity_user_access.length > 0">
                    {{ validations.commodity_user_access.join(', ') }}
                  </small>

              </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button v-if="editSlug == null" variant="success" @click="createCommodity()" v-bind:disabled="isLoading">
                  Save Commodity
                </b-button>
                <b-button v-else variant="success" @click="updateCommodity()" v-bind:disabled="isLoading">
                  Save Commodity
                </b-button>
              </template>
            </b-modal>
          </div>

          <div class="col-md-6">
            <b-button v-if="checkPermission('import commodity price')" v-b-modal.form-commodity-import-modal class="btn btn-primary btn-sm mb-1 float-right" data-toggle="tooltip" data-placement="top" title="Add Commodity">
              <feather-icon
                size="1x"
                :icon="'UploadCloudIcon'"
                :class="'font-medium-1 custom-class'"
              />
              Import Commodity Price
            </b-button>

            <b-modal no-close-on-backdrop id="form-commodity-import-modal" size="lg" :title="'Import Commodity Price'">
              <div class="form">

                <div class="form-group">
                  <label for="">File:</label><br>
                  <input type="file" id="fileImportPrice" ref="fileImportPrice" @change="changeImportPrice()">
                </div>

                <div v-if="isLoadingImport">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <button v-else class="btn btn-primary btn-sm" @click="importPrice()">Import Data</button>
                <br>
                <hr>
                <small>Download import format <span @click="downloadImportFormat()" class="text-primary">here</span></small>
                
              </div>
              <template #modal-footer="{}">
                <div></div>
              </template>
            </b-modal>
          </div>

          <div class="col-md-12">
              <div class="form-row mb-1">
                  <div class="col-md-3">
                      <select class="form-control form-control-sm" v-model="filter.category_id" @change="getData(currentPage)">
                        <option selected value="">-- Select Category --</option>
                        <option v-for="category in categories" v-bind:key="category.slug" v-bind:value="category.id">{{ category.name }}</option>
                      </select>
                  </div>
                  <div class="col-md-9 text-right">
                      <input type="text" v-model="filter.search" placeholder="Search name" class="form-control form-control-sm float-right" style="width: 200px;">
                  </div>
              </div>
          </div>
        </div>
    </div>  

    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Previous Price</th>
            <th>Auction Price</th>
            <th>Amount per Lot</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="6" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.slug">
            <td>{{ item.name }}</td>
            <td>{{ item.category != null ? item.category.name : '-' }}</td>
            <td>
              {{ item.yesterday_price == null ? '-' : item.yesterday_price }}
            </td>
            <td>
              {{ item.last_price }}
            </td>
            <td>{{ item.amount_per_lot + ' ' + item.unit }}</td>
            <td>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button @click="showPriceHistory(item)" class="btn btn-primary btn-sm">
                  <feather-icon
                    size="1x"
                    :icon="'ActivityIcon'"
                    :class="'font-medium-1 custom-class'"
                  />
                </button>
                <button class="btn btn-success btn-sm" v-if="checkPermission('create commodity')" @click="duplicateCommodity(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Copy">
                  <feather-icon
                    size="1x"
                    :icon="'CopyIcon'"
                    :class="'font-medium-1 custom-class'"
                  />
                </button>
                <button class="btn btn-info btn-sm" v-if="checkPermission('update commodity')" @click="editCommodity(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit">
                  <edit-icon size="1x" class="custom-class"></edit-icon>
                </button>
                <button class="btn btn-danger btn-sm" v-if="checkPermission('delete commodity')" @click="deleteCommodity(item.slug)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Delete">
                  <trash-2-icon size="1x" class="custom-class"></trash-2-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="6" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BFormTimepicker, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { Trash2Icon, PlusCircleIcon, EditIcon } from 'vue-feather-icons'
import { quillEditor } from 'vue-quill-editor'
import FileSaver from 'file-saver'
import CommodityPriceHistoryChart from '@/views/components/CommodityPriceHistoryChart.vue'

export default {
  title () {
    return `List Commodity`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    PlusCircleIcon,
    quillEditor,
    EditIcon,
    Trash2Icon,
    BFormTimepicker,
    BSpinner,
    CommodityPriceHistoryChart,
  },
  watch: {
    filter: {
      handler: _.debounce(function() {    
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
        price_history_slug: null,
        editorOption: {
          modules: {
            toolbar: '#toolbar',
          },
        },
        currentPage: 1,
        categories: [],
        result: {},
        filter: {
            category_id: '',
            search: null,
        },
        editSlug: null,
        validations: {},
        formPayload: this.getInitalizeForm(),
        importPricePayload: {
          file: null,
        },
        isLoading: false,
        isLoadingImport: false,
        users: [],
        units: [],
    }
  },
  created() {
    this.loadFilter();
    this.getData();
  },
  mounted() {
    // var context = this
    // this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
    //   if (payload.type == 'commodity-transaction') {
    //     context.getData(context.currentPage || 1);  
    //   }
    // });
  },
  methods: {
    getInitalizeForm() {
      return {
        category_id: '',
        is_sync_to_epact: false,
        epact_commodity_name: '',
        epact_convertion_amount: '',
        cover: null,
        name: '',
        code: '',
        quality: '',
        unit: 'MT',
        amount_per_lot: 100,
        description: '',
        commodity_user_access: [],
      };
    },
    getGrantedRestrictedCommodityPayload() {
      return {
        user_id: '',
        can_see: true,
        can_buy: false,
        can_sell: false
      };
    },
    addGrantedCommodity() {
      this.formPayload.commodity_user_access.push(
        this.getGrantedRestrictedCommodityPayload()
      );
    },
    removeGrantedCommodity(index) {
      this.formPayload.commodity_user_access.splice(index, 1);
    },
    loadFilter() {
        this.$http.get('/public/config')
        .then(response => {
          console.log('response', response)
            this.units = response.data.data.trading__commodity_units.split(",")
        })

        this.$http.get('/admin/categories?per_page=200')
        .then(response => {
            this.categories = response.data.data.data
        })

        this.$http.get('/admin/miscellaneous/auction-seller-fee-payment-list', { useCache: true })
        .then(response => {
          this.sellerFeePaymentOn = response.data.data
        })

        this.$http.get('/admin/miscellaneous/auction-buyer-fee-payment-list', { useCache: true })
        .then(response => {
          this.buyerFeePaymentOn = response.data.data
        })

        this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc', { useCache: true })
        .then(response => {
          this.users = response.data.data.data
        })

    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/commodities', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    changeCover() {
      this.formPayload.cover = this.$refs.file.files[0];
    },
    changeImportPrice() {
      this.importPricePayload.file = this.$refs.fileImportPrice.files[0];
    },
    cleanupForm() {
      this.editSlug = null;
      this.formPayload = this.getInitalizeForm()
      this.validations = {}
    },
    showPriceHistory(item) {
      this.$bvModal.show('modal-commodity-price')
      this.price_history_slug = item.slug
    },
    duplicateCommodity(item) {
      this.editSlug = null
      this.formPayload = {
        category_id: item.category != null ? item.category.id : null,
        is_sync_to_epact: item.is_sync_to_epact,
        epact_commodity_name: item.epact_commodity_name,
        epact_convertion_amount: item.epact_convertion_amount,
        name: item.name,
        code: item.code,
        quality: item.quality,
        description: item.description,
        unit: item.unit,
        amount_per_lot: item.amount_per_lot,
        commodity_user_access: item.commodity_user_access,
      }

      this.$bvModal.show('form-commodity-modal')
    },
    editCommodity(item) {
      this.editSlug = item.slug
      this.formPayload = {
        category_id: item.category != null ? item.category.id : null,
        is_sync_to_epact: item.is_sync_to_epact,
        epact_commodity_name: item.epact_commodity_name,
        epact_convertion_amount: item.epact_convertion_amount,
        cover_url: item.cover_url,
        name: item.name,
        code: item.code,
        quality: item.quality,
        description: item.description,
        unit: item.unit,
        amount_per_lot: item.amount_per_lot,
        commodity_user_access: item.commodity_user_access,
      }

      this.$bvModal.show('form-commodity-modal')
      this.validations = {}
    },
    preparePayload() {

      this.formPayload.is_sync_to_epact = this.formPayload.is_sync_to_epact ? 1 : 0;
      
      var form = new FormData();
      for (var key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) &&  this.formPayload[key] != null) {
          form.append(key, this.formPayload[key]);
        }
      }

      this.formPayload.commodity_user_access.forEach(function(item, index){
        if (item.can_buy || item.can_sell) {
          item.can_see = true;
        }
      });

      // Prepare granted commdity payload
      var context = this;
      this.formPayload.commodity_user_access.forEach(function(item, index){
        if (item.user_id == '' || item.user_id == null) {
          context.removeGrantedCommodity(index);
        } else {
          form.append('commodity_user_access[' + index + '][user_id]', item.user_id);
          form.append('commodity_user_access[' + index + '][can_see]', item.can_see ? 1 : 0);
          form.append('commodity_user_access[' + index + '][can_buy]', item.can_buy ? 1 : 0);
          form.append('commodity_user_access[' + index + '][can_sell]', item.can_sell ? 1 : 0);
        }
      });

      return form;
    },
    updateCommodity() {
      var form = this.preparePayload();
      form.append('_method', 'PATCH');
      this.isLoading = true;

      this.$http.post('/admin/commodities/' + this.editSlug, form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-commodity-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Commodity successfully updated!')
        this.cleanupForm();
        this.isLoading = false;
        this.validations = {};

      })
      .catch(error => {
        const context = this
        if (error.response.data.meta.messages.length > 0 && typeof error.response.data.meta.messages != 'object') {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)          
        } else {
          for (var key in error.response.data.meta.messages) {
            error.response.data.meta.messages[key].forEach(function(message){
              errorNotification(context, 'Oops!', message)
            });
          }
          this.validations = error.response.data.meta.messages;
        }
        this.isLoading = false;
      })
    },
    createCommodity() {
      var form = this.preparePayload();
      this.isLoading = true;

      this.$http.post('/admin/commodities', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-commodity-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Commodity successfully created')
        this.cleanupForm();
        this.isLoading = false;

      })
      .catch(error => {
        const context = this
        if (error.response.data.meta.messages.length > 0 && typeof error.response.data.meta.messages != 'object') {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)          
        } else {
          for (var key in error.response.data.meta.messages) {
            error.response.data.meta.messages[key].forEach(function(message){
              errorNotification(context, 'Oops!', message)
            });
          }
          this.validations = error.response.data.meta.messages;
        }
        this.isLoading = false;
      })
    },
    deleteCommodity(slug) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Its also will delete all auction of this commodity',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.delete('/admin/commodities/' + slug)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Commodity successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
    downloadImportFormat() {
      this.$http.get('/admin/commodity-price/format', {
          responseType: 'blob'
      }).then(response => {
        var fileName = 'format-import-price.csv';
        FileSaver.saveAs(response.data, fileName);
      })
    },
    importPrice() {
      this.isLoadingImport = true;

      var form = new FormData();
      for (var key in this.importPricePayload) {
        if (this.importPricePayload.hasOwnProperty(key) &&  this.importPricePayload[key] != null) {
          form.append(key, this.importPricePayload[key]);
        }
      }
      this.$http.post('/admin/commodity-price/upload', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        successNotification(this, 'Success', 'Price successfully imported')
        this.isLoadingImport = false;

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)  
        }
        this.isLoadingImport = false;
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>